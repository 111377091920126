<div class="container mt-5">
    <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
        <div class="col-lg-2 col-5  m-auto">
            <img src="assets/images/web.png" class="w-100">
        </div>
        <h4 class="text-center text-custom font-7 mt-3 ser-head"> Web Design </h4>
        <p class="font-7 text-center ser">
            Website is a “Branding and design for creative entrepreneurs” it is a powerful Digital
            Marketing Tool. Website can help you to maintain and gain a competitive advantage in your
            industry and improve your business image. Website can develop and offers you many benefits
            including helping you get more leads and prospects, increase sales, enhance your Company
            brand, and improve your customer service. We offer user-friendly and informative websites at
            cost effective rates.
        </p>
        <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
    </div>
</div>

<div class="container mt-5">
    <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
        <div class="col-lg-2 col-5  m-auto">
            <img src="assets/images/logo.png" class="w-100">
        </div>
        <h4 class="text-center text-custom font-7 mt-3 ser-head"> Logo </h4>
        <p class="font-7 text-center ser">
            LOGO Design is a symbol or graphic mark, emblem used to aid and promotes public
            identification and recognition. It can be of a abstract or figurative design or include the
            text of the name it represents as in a word mark. Our talented designers can create
            professional logo design for you. Receive unique and professional logo designs from around
            the world. Our designers are ready to create you the perfect logo. Hire one of our designers
            and launch your logo design contest and receive logo ideas from around the world.
        </p>
        <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
    </div>
</div>

<div class="container mt-5">
    <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
        <div class="col-lg-2 col-5  m-auto">
            <img src="assets/images/seo.png" class="w-100">
        </div>
        <h4 class="text-center text-custom font-7 mt-3 ser-head"> SEO </h4>
        <p class="font-7 text-center ser">
            Social media is another big platform to run ads that bring direct customers at the end of
            the day. We help you run Ads and promotions on social platforms, like ad words, Face book,
            Instagram and LinkedIn, in background here to get new customers ready to buy from you.
        </p>
        <p class=" text-left pl-3"> <span class="text-bold"> Digital Marketing service includes: -
            </span> Digital Marketing strategy | Marketing plan | Action calendar(timeline) | Social
            media marketing | Blog management | Email marketing | Content seeding | Analytics and report
        </p>
        <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
    </div>
</div>

<app-footer></app-footer>