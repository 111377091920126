
<div class="container">
  <div class="row">
      <div class="col-lg-6">
        <h4 class="text-custom font-7 text-bold mt-2 title"> Startup Company Registration </h4>
        <p class="font-7 tagline text-bold"> Legal Documentation For More Business! </p>      
	  </div>
	  <div class="col-lg-6 text-center">
		<a href="tel:9620049886" class="btn btn-sm text-white bg-custom shadow mt-3">9620049886</a> &nbsp;
    <a href="tel:9620806777" class="btn btn-sm text-white bg-custom shadow mt-3">9620806777</a> <br>
    <a href="mailto:info@startupcompanyregistration.com" class="btn btn-sm text-white bg-custom shadow mt-3">info@startupcompanyregistration.com</a> 
	  </div>
  </div>
</div>

<!-- Call Us -->

<div class="container-fluid call-us">
	<div class="row">
		<div class="col-lg-3 bg-custom p-2 col-3 text-center phone-hover phone-icons">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
				aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
				<span class="fa fa-bars" style="color:white; font-size:30px;"></span>
			</button>
		</div>
		<div class="col-lg-3 bg-custom p-2 col-3 text-center phone-icons phone-hover">
			<a href="tel:9620049886" class="text-center"><span class="fa fa-phone text-white"></span></a>
		</div>
		<div class="col-lg-3 bg-custom p-2 col-3 text-center phone-icons phone-hover">
			<a href="https://api.whatsapp.com/send?phone=+919620806777&amp;text=Hi, I contacted you Through your Startup company registration website can i know more about your services?."
				class="text-center"><span class="fa fa-whatsapp text-white"></span></a>
		</div>
		<div class="col-lg-3 bg-custom p-2 col-3 text-center phone-hover">
			<a href="mailto:info@startupcompanyregistration.com" class="text-center"><span
					class="fa fa-envelope text-white"></span></a>
		</div>
	</div>
</div>

	<!-- navigation -->

	<nav class="navbar navbar-expand-lg sticky-top bg-custom shadow mt-2">
		<button class="navbar-toggler m-auto" style="color:black;" type="button" data-toggle="collapse"
			data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
			aria-label="Toggle navigation">
			<span class="fa fa-bars nav-icon text-white" style="font-size:30px; padding:0px;"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarNavDropdown">
			<ul class="navbar-nav m-auto">
				<li class="nav-item border-right border-left">
					<a class="nav-link pr-4 pl-4  font-7" routerLink="/">Home</a>
				</li>
				<li class="nav-item border-right">
					<a class="nav-link pr-4 pl-4  font-7" routerLink="/about">About</a>
				</li>
				<li class="nav-item border-right">
					<a class="nav-link pr-4 pl-4 font-7" routerLink="/startup">Startup Registration</a>
				</li>
				<li class="nav-item border-right">
					<a class="nav-link pr-4 pl-4 font-7" routerLink="/business">Business Registration</a>
				</li>
				<li class="nav-item border-right">
					<a class="nav-link pr-4 pl-4 font-7" routerLink="/overseas">Overseas Reg</a>
				</li>
				<li class="nav-item border-right">
					<a class="nav-link pr-4 pl-4 font-7" routerLink="/digital">Digital Marketing</a>
				</li>
				<li class="nav-item border-right">
					<a class="nav-link pr-4 pl-4 font-7" href="https://rzp.io/l/3iPkL0i">Pay Now</a>
				</li>
				<li class="nav-item border-right">
					<a class="nav-link pr-4 pl-4 font-7" routerLink="/contact">Contact</a>
				</li>
			</ul>
		</div>
	</nav>

	


  <router-outlet></router-outlet>
