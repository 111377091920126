<div class="container-fluid">
    <div class="row">
        <div class="col-lg-3 headings text-center shadow py-2">
            <a href="#gst" class="heading-links btn w-100 border py-2">GST Registration</a>

            <a href="#fssai" class="heading-links btn w-100 border py-2">Fssai License & Registration</a>

            <a href="#legal" class="heading-links btn w-100 border py-2">Legal Metrology Registration</a>

            <a href="#barcode" class="heading-links btn w-100 border py-2">Barcode Registration & Digital Signature</a>

            <a href="#trademark" class="heading-links btn w-100 border py-2">Trademark Registration</a>

            <a href="#iso" class="heading-links btn w-100 border py-2">ISO Certification</a>

            <a href="#import" class="heading-links btn w-100 border py-2">Import Export Code Registration</a>

            <a href="#esi" class="heading-links btn w-100 border py-2">ESI & PF Registration</a>

            <a href="#pan" class="heading-links btn w-100 border py-2">PAN & TAN Registration</a>

            <a href="#12a" class="heading-links btn w-100 border py-2">12A 80G Registration</a>

            <a href="#itr" class="heading-links btn w-100 border py-2">ITR Filling / ROC Filling</a>

            <a href="#software" class="heading-links btn w-100 border py-2">Accounting Software</a>

        </div>
        <div class="col-lg-9 content">
            <img src="assets/images/s-1.jpg" alt="" class="w-100">

            <div class="p-3" id="gst"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/gst.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> GST Registration </h4>
                    <p class="font-7 text-center ser">
                        GST Registration is mandatory for all entities involved in the buying or selling or
                        providing of services in every business
                        engaged in providing services or supply of goods, where the turnover exceeds Rs. 40 Lac or
                        does even a single transaction
                        in an interstate trade. GST has been globally based comprehensive tax levied at a specified
                        rate on sale and consumption of goods and services within a country.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">Benefits: - </span>
                        Open Bank Account | ITC Claim | Easy Loan Approval | Income & Address Proof | Tender
                        Eligibility
                    </p>
                    <p class="text-custom text-bold font-7 ser">GSTR Monthly Filing Types </p>
                    <p class="text-bold font-7 ser">
                        GSTR-3B Filing | GSTR-1 Filing | GSTR-2 Filing | GSTR-3 Filing | GSTR-4 Filing | GSTR-5
                        Filing | GSTR-6 Filing | GSTR-7 Filing | GSTR-8 Filing | GSTR-9 Filing - Annual Returns
                    </p>
                    <p class="font-7 ser">
                        <span class="text-bold">Cancellation of GST Registration - </span>Section 29(1) of CGST Act
                        2017
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
            </div>

            <div class="p-3" id="fssai"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/fssai.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> FSSAI License & Registration </h4>
                    <p class="font-7 text-center ser">
                        Basic Registration | State License |Central License
                    </p>
                    <p class="font-7 ser">
                        FSSAI - Food Safety and Security Act of 2006, sets the standards for healthy and consumable
                        food products.
                        FSSAI expands for Food Safety and Standards Authority of India. It is the governing and
                        regulatory body for
                        ensuring the safety of food products delivered to the consumers. To start a restaurant, or
                        food business you
                        will also need local municipal permissions, Fire Department NOC, etc. - Plan ahead so you
                        can grow quickly.
                    </p>
                    <p class="text-bold">Types of Registration </p>
                    <ul class="mt-1">
                        <li class="font-7 ser text-left">
                            Basic Registration can run Business up to 12 Lakhs
                        </li>
                        <li class="font-7 ser text-left">
                            State Registration can run Business up to 12 Lakhs to 20 Crores
                        </li>
                        <li class="font-7 ser text-left">
                            Central Registration can run Business Above 30 Crores
                        </li>
                    </ul>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
            </div>

            <div class="p-3" id="legal"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/legal-metrolagy.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> Legal Metrology Registration </h4>
                    <p class="font-7 text-center ser">
                        Legal Metrology Act, 2009, the sale or distribution of all packaged goods in India such as
                        export goods,
                        food products and consumer goods require a Legal Metrology Certificate from the Metrology
                        Department of
                        Consumer Affairs. If your business sells or distributes packaged goods in India which
                        includes consumer
                        goods, you need to comply with legal metrology for packaged commodities defined under Legal
                        Metrology
                        Registration provides regulations for the control of measurements and measuring instruments.
                        Legal metrology
                        provides protection of public safety, the environment, consumers, and traders and is
                        critical to fair trade.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">Benefits: - </span>
                        Support of a Civil Society | Technological Education | Reduction of deaths and injuries from
                        accidents |
                        Improvement in the natural environment | Improved health from standardisation of measurement
                        and testing.
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
            </div>

            <div class="p-3" id="barcode"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/barcode.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> Barcode Registration </h4>
                    <p class="font-7 text-center ser">
                        Barcode Registration is an identification system for products. It is a representation of
                        data of a product
                        in the form of parallel lines varying in width and spacing between them. It can readable
                        only by a machine
                        and is widely used in supermarkets, malls and stores etc. for automatic identification of
                        the product. Barcode
                        registration helps with some cell phone app scanners (such as the Zebra app) so that when
                        the product barcode
                        is scanned the product information will appear. Barcode registration will increase the
                        internet profile of the
                        product so that when the barcode is searched for on Google or another search engine the
                        product information will
                        appear. We offer barcode registration services across PAN India.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-bold">Types of Barcodes: - </span>One dimensional | Two dimensional | Data
                        Bar Family
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">Benefits: - </span>
                        Barcode helps in internal control | Barcodes eliminate the possibility of human error|
                        Barcodes provide better data | Barcodes promote better decision making & saves both time and
                        money.
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>

                    <div class="text-center bg-white p-2 mt-5" data-aos="fade-up" data-aos-delay="200">
						<div class="col-lg-2 col-5  m-auto">
							<img src="assets/images/digital-signature.png" class="w-100">
						</div>
						<h4 class="text-center text-custom font-7 mt-3 ser-head"> Digital Signature Certificate </h4>
						<p class="font-7 text-center ser">
							Digital Signature Certificate offers more security than an electronic signature. The unique
							identifying
							“fingerprint” data in a digital signature remains permanently embedded within a document.
							Signs that someone
							has tampered with or altered a document after signing it can be easily detected. It is used
							as an online
							identity verification tool for an entity. They are basically electronic certificates
							confirming one’s identity.
							Using DSC, one can access all information and services related to the entity, and sign
							documents electronically.
							This certificate is used worldwide, and in India, it can only be issued by authorized
							agencies.
						</p>
						<p class="font-7 ser">
							<span class="text-bold">Types of DSC </span>
							<br> Class I <br> Class II <br> Class III
						</p>
						<p class="font-7 ser">
							<span class="text-custom">Benefits: - </span>
							Saves Time, Cost Savings, Workflow Efficiency Better Customer Experience, Security, Legal
							Validity, Future Validity, Environmental Benefits, Business efficiency
						</p>
						<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
					</div>
                </div>
            </div>

            <div class="p-5" id="trademark"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/tm.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> Trademark Registration </h4>
                    <p class="font-7 text-center ser">
                        Trademark Registration Act, 1999 Protects your Brand Name and Innovation Before you starts
                        your Business.
                        It is a unique identity Logo or word(s) used to represent a business or its products. Once
                        registered,
                        that same Logo or series of words cannot be used by any other organisation, forever, as long
                        as it remains
                        in use and proper paperwork and fees are paid. A trademark once registered it is valid for
                        10 years subject
                        to renewal after that you can use TM / SM after trademark application is submitted with the
                        department.
                        After approval of the application, you can use (TM) to know more about trademark.
                        Intellectual property
                        rights help in providing exclusive rights to creator or inventor thereby induces them to
                        distribute and
                        share information and data instead of keeping it confidential.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">
                            TM / SM Registration: -
                        </span>
                        Trademark Protects Logo | Brand Name | Brand Image | Colour | Sound | Symbol | Coined |
                        Letters | Numerals |
                        Taglines | Shapes | emblem | Sign | Stamp | device | Badge | Crest | Insignia | Seal |
                        Shield | Motif |
                        Hallmark | Mark | Figure | Monogram | Colophon ( / ' S ? l ? f ?n, -f ? n /)
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
            </div>

            <div class="p-3" id="iso"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/iso.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> ISO Certification </h4>
                    <p class="font-7 text-center ser">
                        ISO - International Standards Organization is an independent organisation that develops
                        International Standards
                        to ensure the organizations across the world deliver products / services that are safe,
                        reliable and of good quality.
                        ISO standards can reduce costs, satisfy consumers, open access to new markets, improve
                        environmental performance
                        ,If you’re an entrepreneur looking to expand your business with government tenders or large
                        corporations, your business
                        needs an “ISO Certification”. We are partner with ISO and Product certification
                        organizations across the globe to
                        broaden their market to reach & help the customer accredited their business transformation.
                        We will help you to
                        obtain <span class="text-bold"> ISO 9001:2015 QMS / ISO 10002:2014 QMC / ISO/IEC 20000:2011: ITSM / 27001:2005 ITMS / ISO 22000:2018 FSMS / HACCP / ISO 45001:2018 OHSAS / ISO 14001:2015 EMS </span>
                        & other certification accredited by International Accreditation bodies from USA ,UK,
                        Singapore, Dubai and other Countries....
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">Benefits: - </span>
                        Brand Image | Increase revenue | Go Global | Better Quality | Customer Satisfaction | Tender
                        Eligibility | Improve Efficiency | Employee Satisfaction
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
            </div>

            <div class="p-3" id="import"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/import-export.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> Import Export Code Registration </h4>
                    <p class="font-7 text-center ser">
                        Import Export Code is required by any individual and companies who is willing to start an
                        import-export business,
                        issued by the DGFT (Director General of Foreign Trade). IEC has a 10-digit code which has
                        lifetime Validity.
                        Traders can’t import goods without the Import Export Code and comparatively, the exporter
                        vendor can’t profit
                        by DGFT for the export without IEC code. Registering for Import Export Code will help you in
                        expanding your business
                        and reaching out to a larger market on a global scale, thereby helping your business to
                        grow. Business owners who wish to
                        expand their businesses into global markets can register for Import Export Code and gain
                        access to a larger audience.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">Benefits: - </span>
                        Business growth | Lifetime Validity | Custom Clearance | Cost Reduction | Export Subsidies |
                        No Return Filings
                        <br>&nbsp; <br>&nbsp;
                        <br>&nbsp; <br>&nbsp;
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
            </div>

            <div class="p-3" id="esi"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/esi.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> ESI Registration </h4>
                    <p class="font-7 text-center ser">
                        ESI Registration - Employees' State Insurance Corporation, is a need based social insurance
                        scheme incorporated under Employees'
                        ' State Insurance Act 1948, in order to assure better medical care to the employees or
                        workers and their immediate dependants.
                        Any Manufacturing factories or establishment employing 10 or more persons drawing wages of
                        less than Rs.21, 000 per month must
                        obtain ESI registration and comply with ESI regulations. We will assist and ensure
                        compliance under ESI regulations. The rates
                        are reviewed from time to time. Currently, the employee's contribution rate is 0.75% of the
                        wages and that of employer's is 3.25%
                        of the wages paid/payable in respect of the employees in every wage period.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">Benefits : </span>
                        Medical Benefit | Sickness Benefit | Maternity Benefit | Disablement Benefit | Dependants
                        Benefit | Funeral Expenses| Confinement Expenses
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                    <div class="text-center bg-white p-2 mt-5" data-aos="fade-up" data-aos-delay="200">
                        <div class="col-lg-2 col-5  m-auto">
                            <img src="assets/images/pf.png" class="w-100">
                        </div>
                        <h4 class="text-center text-custom font-7 mt-3 ser-head"> Provident Fund Registration </h4>
                        <p class="font-7 text-center ser">
                            Provident Fund: - The EPF-Employee Provident Fund is one of the main platforms of savings
                            for all employees working in Government,
                            Public or Private sector Organizations. Every establishment with 20 or more employees who is
                            engaged in any one or more of the
                            industries specified in Schedule 1, of the Employees Provident Fund and Miscellaneous
                            Provisions Act 1952 must register and contribute
                            for Employee provident fund. Provident fund is required when a company has employed 15 or
                            more persons. Employees contribute 12%
                            and the employer contributes 12% towards the EPF fund. All employees are eligible to become
                            a member of provident Fund from the
                            date of joining the organization. On becoming a member, an employee is eligible for
                            provident Fund benefits, pension benefits and
                            Insurance benefits
                        </p>
                        <p class="font-7 ser">
                            <span class="text-custom">Benifits :- </span>
                            pension benefits and Insurance benefits
                        </p>
                        <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                    </div>
                </div>               
            </div>

            <div class="p-3" id="it"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/Professional-tax.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> Professional Tax Registration </h4>
                    <p class="font-7 text-center ser">
                        PT - Professional Tax Registration is mandatory for every company which is engaged in any
                        trade or professional should
                        obtain a Certificate of Enrolment from the Profession Tax Officer of jurisdiction as per
                        Karnataka Tax on Professions,
                        Trades, Callings and Employments Act, 1976. All companies with Enrolment Certificate shall
                        pay tax every year before 30th
                        of April. It is a state-level tax and has to be compulsorily paid by every member of staff
                        employed in private companies.
                        The owner of a business is responsible to deduct professional tax from the salaries of his
                        employees and pay the amount so
                        collected to the appropriate government department. Penalty for non-payment of tax by
                        enrolled person and registered employer
                        with interest at rate of 1.25% per month and Penalty not exceeding 50% of the tax amount
                        due.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">Benefits: - </span>
                        Avoid Penalty| Tax Deductible | Easy Compliance State Government Tax
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
            </div>

            <div class="p-3" id="pan"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/pan-tan.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> PAN Registration & TAN Registration
                    </h4>
                    <p class="font-7 text-center ser">
                        <span class="text-custom">PAN Registration: - </span>
                        Permanent Account Number (PAN) is a ten-digit alphanumeric number, issued in the form of a
                        laminated card, by the Income Tax.PAN
                        enables the department to link all transactions of the “person” with the department. These
                        transactions include tax payments, TDS/TCS credits,
                        returns of income/wealth/gift / FBT, specified transactions, correspondence, and so on. PAN,
                        thus, acts as an identifier for the “person” with the tax department.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">TAN Registration: - </span>
                        TAN i.e. Tax Deduction and Collection Account Number is a 10 digit alpha numeric number
                        required to be obtained by all persons who are
                        responsible for deducting tax at source or collecting tax at source. It is compulsory to
                        quote TAN in TDS/TCS return (including any e-TDS/TCS return),
                        any TDS/TCS payment Challan, TDS/TCS certificates, Annual Information Return and other
                        documents as may be prescribed.
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
            </div>

            <div class="p-3" id="12a"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/12a-80g.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> 12A 80G Registration </h4>
                    <p class="font-7 text-center ser">
                        <span class="text-bold"> 80G Certificate </span> is issued to a non-profit organization or
                        non-governmental organizations (NGO),
                        a charitable trust or a Section 8 Company by the Income Tax Department.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-bold">12A registration </span>Trusts and NGOs and other Section 8
                        companies enjoy exemption from paying income
                        tax. NGOs are basically organizations that are meant for charitable and non-profit
                        activities.
                    </p>
                    <p class="text-bold">
                        Benefits of 12A Registration & 80G Certification
                    </p>
                    <p class="text-custom">
                        12A Registration
                    </p>
                    <ul class="">
                        <li class="font-7 ser text-left">
                            Income will be exempted from taxation.
                        </li>
                        <li class="ser font-7 text-left">
                            Benefits of taking grants from the Government, Abroad, other agencies.
                        </li>
                        <li class="font-7 ser text-left">
                            The Registration done once is valid for a lifetime. No renewal.
                        </li>
                    </ul>
                    <p class="text-custom">
                        80G Certification
                    </p>
                    <ul class="">
                        <li class="font-7 ser text-left">
                            The donor can avail deduction from its taxable income.
                        </li>
                        <li class="ser font-7 text-left">
                            Attracts the donor to make a donation.
                        </li>
                        <li class="font-7 ser text-left">
                            The Registration done once is valid for a lifetime. No renewal.
                        </li>
                    </ul>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
            </div>

            <div class="p-3" id="itr"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/itr.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> ITR Filling </h4>
                    <p class="font-7 text-center ser">
                        ITR Filing: Income Tax Return or ITR is the process of filing your tax returns at the end of
                        a financial year, Make sure
                        to file your income tax return on or before the due date announced by the Government to
                        avoid penalties. Income refers
                        to the total income from the five heads of income and is calculated before allowing any
                        deductions under sections 80C to 80U of the Income-tax Act, 1961.
                    </p>
                    <p class="font-7 ser">
                        ITR Filing As per section 139(1) of Income Tax Act,1961, every person being a company or a
                        firm; HUFs Hindustan Undivided
                        family’s or being a person other than a company or a firm, if his total income is above the
                        the maximum amount which is not
                        chargeable to income-tax, he will have to furnish and file a return of his income.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">Benefits: - </span>
                        Easy loans approvals | To claim TDS refunds | Carry forward of losses | VISA processing |
                        Self employed | Avoid interest on tax liability
                    </p>
                    <p class="font-7 ser">
                        <span class="text-bold">Types of ITR Filling: - </span>
                        ITR-1 or SAHAJ: Resident Indian individuals and HUFs | ITR-2: HUFs and individuals | ITR-3:
                        Partner in a firm, HUF, or individuals |
                        ITR-4 or Sugam Firm: HUF, or individual | Form 16: employers to salaried individuals in the
                        country | ITR-5: LLPs or Partnership Firms |
                        ITR-6: Companies | ITR-7: NGO, Trust, Society, Association
                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                    <div class="text-center bg-white p-2 mt-5" data-aos="fade-up" data-aos-delay="200">
                        <div class="col-lg-2 col-5  m-auto">
                            <img src="assets/images/roc.png" class="w-100">
                        </div>
                        <h4 class="text-center text-custom font-7 mt-3 ser-head"> ROC Filling </h4>
                        <p class="font-7 text-center ser">
                            As per The Companies Act, 2013 Every company is required to file the Audited financial
                            statement and annual return. Filing of Audited financial statement is governed under Section
                            129 and 137 of The Companies Act, 2013 read with Rule 12 of the Company (Accounts) Rules,
                            2014 and annual return is governed under Section 92 of the Companies Act,2013 read with Rule
                            11 of the Companies (Management and Administration) Rules, 2014.
                        </p>
                        <ul class="mt-2 font-7 text-left ser">
                            <li>
                                Hold a Board Meeting to Authorize the auditor for the preparation of financial
                                statements & the Director or Company Secretary for preparation of Board Report and
                                Annual Return
                            </li>
                            <li>
                                Hold another Board Meeting for approving the financial statements, Board Report and
                                Annual Return by the directors of the company.
                            </li>
                            <li>
                                Conduct the Annual General meeting of the Company and pass the necessary resolutions.
                            </li>
                            <p class="text-bold text-custom">Annual e-Filing Guidelines</p>
                            <p class="font-7 ser">As a part of Annual e-Filing, Companies incorporated under the
                                Companies Act, 1956 are required to e-file the following documents with the Registrar of
                                Companies (Rocs):</p>
                            <p class="font-7 ser">
                                KYC : Of all the directors | Balance-Sheet | Profit & Loss Account | Annual Return |
                                Compliance Certificate | Cost Audit report
                            </p>
                        </ul>
                        <a href="contact.html" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                    </div>
                    <div class="text-center bg-white p-2 mt-5" data-aos="fade-up" data-aos-delay="200">
                        <div class="col-lg-2 col-5  m-auto">
                            <img src="assets/images/cs.png" class="w-100">
                        </div>
                        <h4 class="text-center text-custom font-7 mt-3 ser-head"> Company Secretarial Services </h4>
                        <p class="font-7 text-center ser">
                            CS experts in Company law and we are to help you. Our team leads the entrepreneurs to run
                            their business in secured way in all aspects. Our Company Secretarial services will provide
                            you from Incorporation to Winding up.
                        </p>
                        <ul class="mt-2">
                            <li class="ser text-left">
                                Shifting of Registered office
                            </li>
                            <li class="text-left ser">
                                Changes in the Objective of the company
                            </li>
                            <li class="text-left ser">
                                Amendments to Incorporation Agreements
                            </li>
                            <li class="text-left ser">
                                Change of Directors/Partners & Additions /Removal
                            </li>
                            <li class="text-left ser">
                                Increase in Authorized Capital
                            </li>
                            <li class="text-left ser">
                                Allotment of Shares
                            </li>
                            <li class="text-left ser">
                                Conversion of Private to Public vice versa
                            </li>
                            <li class="text-left ser">
                                Conversion of OPC to Private & Public vice versa
                            </li>
                            <li class="text-left ser">
                                Mergers, Amalgamations, De mergers
                            </li>
                            <li class="text-left ser">
                                Due Diligence Audit
                            </li>
                            <li class="text-left ser">
                                Company Credit Check Report
                            </li>
                            <li class="text-left ser">
                                Name Change of Company with Board Meeting & Filing of Name
                            </li>
                            <li class="ser text-left">
                                Winding up Company (Closing the Company)
                            </li>
                            <li class="text-left ser">
                                Changing the name of the company involves alternating process of Memorandum of
                                Association. The memorandum can be altered by way of passing a special resolution as per
                                section 13 of Companies Act, 2013
                            </li>
                        </ul>
                        <a href="contact.html" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
                <div class="text-center bg-white p-2 mt-5" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/closing-company.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-5 ser-head"> Closing of Company </h4>
                    <p class="font-7 text-center ser">
                        Closing of Company:-Closing the company under the FAST TRACK EXIT MODE is the simplest and
                        the fastest mode of closing the company. All Startups who register their company as a
                        private limited must comply with the companies Act 2013 and do the necessary filing such as
                        MGT-7, AOC-4 and ADT-1. The filings are necessary whether the company has started its
                        operation or not. Failing to comply will attract penalty under the Act. If a company does
                        not have operations it is advisable to close the company or file for dormant status.
                    </p>
                    <p class="text-bold mt-5 font-7"> There are some key tasks to consider including</p>
                    <ul class="mt-2 font-7 ser text-left">
                        <li>
                            Cancelling your business registrations
                        </li>
                        <li>
                            Meeting your tax requirements
                        </li>
                        <li>
                            Notifying employees and ensuring they receive their entitlements
                        </li>
                        <li>
                            Ending or assigning lease agreements
                        </li>
                    </ul>
                    <a href="contact.html" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                </div>
                </div>
                
            </div>

            <div class="p-3" id="software"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-lg-2 col-5  m-auto">
                        <img src="assets/images/accounting-software.png" class="w-100">
                    </div>
                    <h4 class="text-center text-custom font-7 mt-3 ser-head"> Accounting Software </h4>
                    <p class="font-7 text-center ser">
                        Accounting software is a fundamental application that allows an organization to record the
                        flow of money for internal and external
                        review and auditing. It is the primary tool for assessing the financial health of the
                        organization. Accounting software can optimize
                        your business, depending on its main features and objectives. From simplified invoicing to
                        complex tax reporting, accounting systems
                        come with all customised features that are not even feasible without an appropriate system.
                    </p>
                    <p class="font-7 ser">
                        <span class="text-custom">Complete Business ERP Software : </span>
                        India’s No.1 Customized Now you can create yourself any kind of vouchers level of reports,
                        no programming knowledge is required,
                        no experience is required design yourself Specialized to managed by any Traders,
                        Distributors, Retail Shops, Dealer, Electronics
                        and Electricals, Mobile Dealers, FMCG etc. Designed to help you eliminate all the
                        complexities in business automation like organizing
                        your Financial Accounts, Powerful Inventory Control, Label Printing, Cheque Printing,
                        Illustrative Log Book, Window Based / DOS Based
                        Printing, With Inventory / Without Inventory Accounting, User-definable Optional Fields in
                        Masters and Vouchers, Export Facility for
                        each report to MS Word, Excel, PDF, WordPad, HTML, SMS Plugin, E-mail ,Unlimited Fully
                        Configurable Invoice Formats, Multi Company management,
                        Intelligent Outstanding Analysis.

                    </p>
                    <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
                    <div class="text-center bg-white p-2 mt-5" data-aos="fade-up" data-aos-delay="200">
						<div class="col-lg-2 col-5  m-auto">
							<img src="assets/images/freecharge.png" class="w-100">
						</div>
						<h4 class="text-center text-custom font-7 mt-3 ser-head"> Freecharge Registration </h4>
						<p class="font-7 text-center ser">
							Free Charge is one of a India’s leading digital payments app for financial services .Free
							Charge services are available across
							a range of financial instruments including savings, payments, insurance, and investment and
							lending. A wholly owned subsidiary
							of Axis Bank A base of over 60 mn customers Payments possible through wallets, payment
							gateways, UPI, net banking, Credit Card,
							Debit card and now universal QR code Exciting rewards, offers and deals on payments means
							more customers for you. Free Charge consumers
							can pay utility bills (Electricity, Gas), pay Landline bills or recharge Mobile, Broadband,
							DTH and Metro cards. FreeCharge
							covers major network providers in India, that include Airtel, Aircel, Vodafone, BSNL, Idea,
							Tata Docomo (GSM), Jio etc. Not
							only that, to facilitate the online recharges, the latest talk time plans and data packs are
							updated on the FreeCharge website
							accordingly. This makes the data more reliable, and the interface becomes more user-friendly
							for users.
						</p>
						<p class="font-7 ser">
							<span class="text-custom">Benefits :- </span>
							Customers can make payments using ANY UPI or BANKING apps Instant settlements in accounts of
							customer and shopkeeper
							Secure and instant transaction compared to credit/debit cards No hassles of setting up
							terminals Most convenient and
							secure way to make and receive payments
						</p>
						<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
					</div>
                </div>
            </div>
            

        </div>
    </div>
</div>

<app-footer></app-footer>