<img src="assets/images/about-banner.jpg" alt="" class="w-100">
<div class="container mt-4 shadow py-4">
    <h2 class="font-7 text-custom P-2 text-bold txt-blink"> About Us </h2>
				<p class="font-7 mt-4 P-2 text-bold">
					Stars Groups is a “Trusted Consultant for your Business Success”
					www.startupcompanyregistration.com is a One Stop Solution for your Dream Business Set-Up and
					Corporate Company Services, managed by Highly Qualified Specialized team with CS – Company
					Secretaries, Financial Professionals,
					ISO Consultant & Auditors. Our Services for startups , Proprietorship to incorporate businesses for
					Retailers Traders, IT Industry, Educational Institutional, International Companies,
					NGO , Societies, Trusts, E-commerce, Financial institutions etc, business approach follow the way of
					formal business efficiency with highly qualified Consultant & Experts.
				</p>
				<h4 class="mt-3 font-7">
					Our Core Services: -
				</h4>
				<p class=" font-7">
					<span class="text-bold">Startup Registration: - </span>
					Proprietorship Registration | Partnership Firm Registration | LLP Registration - Limited Liability
					partnership Registration | OPC Private Limited Registration |
					Private Limited Company | Foreign Subsidy Company | Section8 NGO Registration | Trust Registration |
					Society Registration | Association Registration | MSME Registration
					| Startup Registration Packages
				</p>
				<p class="font-7">
					<span class="text-bold">Business Registration: - </span>
					GST Registration | FSSAI License & Registration | Legal Metrology Registration | Barcode
					Registration | DSC-Digital Signature Certificate |
					IE- Import Export Code Registration | APEDA Registration | Spices Board Registration | Trademark
					Registration | ISO Certification |
					PT - Professional Tax Registration | PF - Provident Fund Registration | ESI Registration | PAN & TAN
					Registration | 12A 80G Registration |
					ITR Filling | Accounting Software | Free charge Registration
				</p>
				<p class="font-7">
					<span class="text-bold">Post Incorporation Services: - </span>
					CS Services | ROC Filling | Closing of Company
				</p>
				<p class="font-7">
					<span class="text-bold">Overseas Startup Registration: - </span>
					LLC Registration | INC Registration | Trademark Registration
				</p>
				<p class="font-7">
					<span class="text-bold">Digital Marketing: - </span>
					Web Design | Logo Design | SEO Services
				</p>
				<p class="font-7">
					www.startupcompanyregistration.com company incorporation initiative of Stars Groups!
				</p>
				<p class="text-bold">
					Our Branch: - Bangalore, Chennai, Cochin, Coimbatore, Trichy, Madurai, Tirupur, Delhi, Mumbai,
					Hyderabad, Ahmadabad & also in Pan India.
                </p>
                <h4 class="text-bold">
					Are you looking for Company Registration / Business Registration in India & Overseas?
				</h4>
				<p class="mt-2">
					Our Consultants will guide you in getting all necessary Documents and Registration required getting Company / Business Registration in India & Overseas, please click on the following link to get connect with us. 
				</p>
				<a href="tel:9620049886" class="btn btn-lg text-white bg-custom shadow mt-3">9620049886</a> &nbsp;
				<a href="tel:9620806777" class="btn btn-lg text-white bg-custom shadow mt-3">9620806777</a> <br>
				<a href="mailto:info@startupcompanyregistration.com" class="btn btn-lg text-white bg-custom shadow mt-3">info@startupcompanyregistration.com</a> 
</div>

<app-footer></app-footer>