import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overseas',
  templateUrl: './overseas.component.html',
  styleUrls: ['./overseas.component.css']
})
export class OverseasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
