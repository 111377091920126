<!-- swiper -->

<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img class="d-block w-100" src="assets/images/s1.jpg" alt="First slide">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100" src="assets/images/s2.jpg" alt="First slide">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100" src="assets/images/s3.jpg" alt="Third slide">
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>

<div class="container py-4 mt-4 shadow text-center">
    <h2 class="text-bold">
        About <span class="text-custom"> Us </span>
    </h2>
    <p class="mt-3">
                    Startup Company Registration in Bangalore we will help in Legal documentation for Startup Business, Company Registration, 
                    Trademark Registration, GST Registration & ISO Certification, CS & CA Assistance sitting at home. Our Services for startups,
                    Proprietorship to incorporate businesses for Retailers Traders, IT Industry, Educational Institutional, International Companies,
					NGO , Societies, Trusts, E-commerce, Financial institutions etc..
    </p>
    <h4 class="text-bold">
        Are you looking for Company Registration / Business Registration in India & Overseas?
    </h4>
    <p class="mt-2">
        Our Consultants will guide you in getting all necessary Documents and Registration required getting 
        Company / Business Registration in India & Overseas, please click on the following link to get connect with us. 
    </p>
    <a href="tel:9620049886" class="btn btn-lg text-white bg-custom shadow mt-3">9620049886</a> &nbsp;
    <a href="tel:9620806777" class="btn btn-lg text-white bg-custom shadow mt-3">9620806777</a> <br>
    <a href="mailto:info@startupcompanyregistration.com" class="btn btn-lg text-white bg-custom shadow mt-3">info@startupcompanyregistration.com</a> 
</div>

<div class="container-fluid bg-custom py-4 mt-4">
   <div class="container">
       <h1 class="text-center text-white text-bold">
           Our Services
       </h1>
    <div class="row">
        <div class="col-lg-6 text-center">
            <div class="col-lg-12 text-bold bg-light shadow py-4 ser mt-4">
                <h3 class="text-custom">
                    Startup Registrations
                </h3>
                <p class="text-bold mt-3">
                    Proprietorship
                </p>
                <p class="text-bold mt-3">
                    Partnership Firm
                </p>
                <p class="text-bold mt-3">
                    Limited Liability Firm
                </p>
                <p class="text-bold mt-3">
                    Private Limited Company
                </p>
                <a routerLink="/startup" class="btn btn-lg text-white bg-custom shadow mt-3">Know More</a>
            </div>
        </div>

        <div class="col-lg-6 text-center">
            <div class="col-lg-12 text-bold bg-light shadow py-4 ser mt-4">
                <h3 class="text-custom">
                    Business Registrations
                </h3>
                <p class="text-bold mt-3">
                    GST Registration
                </p>
                <p class="text-bold mt-3">
                    Fssai License & Registration
                </p>
                <p class="text-bold mt-3">
                    Trademark Registration
                </p>
                <p class="text-bold mt-3">
                    ISO Certification
                </p>
                <a routerLink="/business" class="btn btn-lg text-white bg-custom shadow mt-3">Know More</a>
            </div>
        </div>

        <div class="col-lg-6 text-center">
            <div class="col-lg-12 text-bold bg-light shadow py-4 ser mt-4">
                <h3 class="text-custom">
                    Overseas Registration
                </h3>
                <p class="text-bold mt-3">
                    Limited Liability Company
                </p>
                <p class="text-bold mt-3">
                    INC In-Corporation
                </p>
                <p class="text-bold mt-3">
                    Trademark Registration
                </p>
                <a routerLink="/overseas" class="btn btn-lg text-white bg-custom shadow mt-3">Know More</a>
            </div>
        </div>

        <div class="col-lg-6 text-center">
            <div class="col-lg-12 text-bold bg-light shadow py-4 ser mt-4">
                <h3 class="text-custom">
                    Digital Marketing
                </h3>
                <p class="text-bold mt-3">
                    Web Design
                </p>
                <p class="text-bold mt-3">
                    LOGO Design
                </p>
                <p class="text-bold mt-3">
                    Search Engine Optimization
                </p>
                <a routerLink="/digital" class="btn btn-lg text-white bg-custom shadow mt-3">Know More</a>
            </div>
        </div>
    </div>
   </div>
</div>

<div class="container-fluid py-3 mt-5 " style="overflow-x: hidden;">
    <h2 class="text-bold text-center text-custom">Why Choose Us?</h2>
    <div class="row">
        <div class="col-lg-3 p-2 text-center mt-3 border shadow" data-aos="fade-right" data-aos-duration="1000">
            <div class="col-lg-4 col-5 m-auto mt-3 ">
                <h1 class="fa fa-check-circle-o text-custom" style="font-size: 60px;"></h1>
            </div>
            <h4 class="text-bold">High Standard</h4>
            <p class="mt-2">
                We are an ISO 9001:2015 Certified consulting firm with the trained Consultant to assess you on Legal
                documentation
            </p>
        </div>
        <div class="col-lg-3 p-2 text-center mt-3 border shadow" data-aos="fade-down" data-aos-duration="1000">
            <div class="col-lg-4 col-5 m-auto mt-3 ">
                <h1 class="fa fa-clock-o text-custom" style="font-size: 60px;"></h1>
            </div>
            <h4 class="text-bold">Quick Turnaround - TAT</h4>
            <p class="mt-2">
                Quick Turnaround is the time interval from the time of submission of a process, our transparency to
                complete the process on time
            </p>
        </div>
        <div class="col-lg-3 p-2 text-center mt-3 border shadow" data-aos="fade-up" data-aos-duration="1000">
            <div class="col-lg-4 col-5 m-auto mt-3 text-custom">
                <h1 class="fa fa-money " style="font-size: 60px;"></h1>
            </div>
            <h4 class="text-bold">Competitive Price</h4>
            <p class="mt-2">
                Our prices are very competitive in the market, we provide a premium services with cost effective,
                affordable and reliable on legal documentation
            </p>
        </div>
        <div class="col-lg-3 p-2 text-center mt-3 border shadow" data-aos="fade-left" data-aos-duration="1000">
            <div class="col-lg-4 col-5 m-auto mt-3">
                <h1 class="fa fa-user-o text-custom" style="font-size: 60px"></h1>
            </div>
            <h4 class="text-bold">Qualified Team</h4>
            <p class="mt-2">
                Our team of Highly Qualified professionals with CS, CA, Auditors and Consultant to guide you with
                the updated information on the legal documentation
            </p>
        </div>
    </div>
</div>

<app-footer> </app-footer>