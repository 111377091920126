

<div class="footer bg-custom p-2 mt-5" style="position:absolute; z-index: 9999;">
    <div class="row">
        <div class="col-lg-6">
             <div class="col-lg-8 m-auto text-center">
                <h5 class="text-white mt-2">Fill your details Our Consultant will Call You Back</h5>
                <form action="callback.php" method="post" class="enquiry">
                    <input type="text" name="pname" placeholder="Enter Your Name" class="ipt mt-3 shadow" required> <br>
                    <input type="text" name="phone" placeholder="Enter Your Phone Number" class="ipt mt-3 shadow" required> <br>
                    <input type="text" name="email" placeholder="Enter Your Email Id" class="ipt mt-3 shadow" required> <br>
                    <input type="submit" class="btn btn-success btn-lg shadow mt-3" value="Submit">
                </form>
            </div>
        </div>
        <div class="col-lg-6">
            <iframe class="shadow"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d124438.44458223738!2d77.51783887840493!3d12.926903719916856!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16c2be83d515%3A0xeacee4a332eb2596!2sStars+Groups!5e0!3m2!1sen!2sin!4v1548052509147"
            width="100%" height="250" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4 mt-3">
            <h3 class="text-white font-7"> About Us</h3>
            <p class="text-white mt-3 font-7">
                    Startup Company Registration in Bangalore we will help in Legal documentation for Startup Business, Company Registration, 
                    Trademark Registration, GST Registration & ISO Certification, CS & CA Assistance sitting at home. Our Services for startups,
                    Proprietorship to incorporate businesses for Retailers Traders, IT Industry, Educational Institutional, International Companies,
					NGO , Societies, Trusts, E-commerce, Financial institutions etc..
            </p>
        </div>
        <div class="col-lg-2 text-center mt-5">
            <h5 class=" text-white"> Follow Us </h5>
            <div class="row mt-5" style="justify-content: center;">
                <div class="col-lg-2 col-2 wow bounce" data-wow-delay="0.2s"><a href="https://www.facebook.com/sylvesterstartup/?ref=pages_you_manage" target="_blank" class="text-white"><span
                        class="fa fa-facebook pr-3 ic" style="font-size:32px;"></span></a></div>
                <div class="col-lg-2 col-2 wow bounce" data-wow-delay="0.4s"><a href="https://twitter.com/StartUpCompany3" target="_blank" class="text-white"><span
                        class="fa fa-twitter pr-3 ic" style="font-size:32px;"></span></a></div>
                <div class="col-lg-2 col-2 wow bounce" data-wow-delay="0.6s"><a href="https://www.instagram.com/startupcompany600/?hl=en" target="_blank" class="text-white"><span
                        class="fa fa-instagram pr-3 ic" style="font-size:32px;"></span></a></div>
                <div class="col-lg-2 col-2 wow bounce" data-wow-delay="0.8s"><a href="https://www.linkedin.com/company/79719938/admin/" target="_blank" class="text-white"><span
                        class="fa fa-linkedin ic" style="font-size:32px;"></span></a></div>
                <div class="col-lg-2 col-2 wow bounce" data-wow-delay="0.9s"><a href="https://www.youtube.com/channel/UCWMMLbkILzpShMV8iCRmyag/about" target="_blank" class="text-white"><span
                        class="fa fa-youtube-play ic" style="font-size:32px;"></span></a></div>
            </div>
        </div>
        <div class="col-lg-3 text-white text-center">
            <h3 class=" mt-4">Quick Links</h3>
            <a routerLink="/" class="text-white"><h6 class="mt-4">Home</h6></a>
            <a routerLink="/about" class="text-white"><h6 class="mt-1">About Us</h6></a>
            <a routerLink="/startup" class="text-white"><h6 class="mt-1">Startup Registration</h6></a>
            <a routerLink="/business" class="text-white"><h6 class="mt-1">Business Registration</h6></a>
            <a routerLink="/overseas" class="text-white"><h6 class="mt-1">Overseas Registration</h6></a>
            <a routerLink="/digital" class="text-white"><h6 class="mt-1">Digital Marketing</h6></a>
            <a routerLink="/refund" class="text-white"><h6 class="mt-1">Disclaimer & Refund Policy</h6></a>
            <a routerLink="/contact" class="text-white"><h6 class="mt-1">Contact</h6></a>
        </div>
        <div class="col-lg-3 text-center text-white">
            <h1 class="fa fa-map-marker mt-3" style="font-size:30px;"> </h1>
            <p class="font-7">Sony Complex 10/3 2nd Floor Maruthi Sevanagar Next to Orion East Mall, Dodda Banaswadi Main Rd, Bengaluru, Karnataka 560033 </p>
            <h1 class="fa fa-envelope mt-2" style="font-size:30px;"> </h1>
            <p class="font-7">info@startupcompanyregistration.com </p>
            <h1 class="fa fa-phone mt-2" style="font-size:30px;"> </h1>
            <p class="font-7">Phone : +91 9620049886 </p>
        </div>
    </div>
</div>

<a class="btn btn-lg bg-black text-white right-app" routerLink="/contact">Appointment </a>