<div class="container-fluid">
    <div class="row">
        <div class="col-lg-3 headings text-center shadow py-2" id="headings">
            <a href="#proprietorship" class="heading-links btn w-100 border py-2">Proprietorship</a>
            <a href="#Partnership" class="heading-links btn w-100 border py-2">Partnership Firm</a>
            <a href="#llc" class="heading-links btn w-100 border py-2">Limited Liability Company</a>
            <a href="#pvt" class="heading-links btn w-100 border py-2">Private Limited</a>
            <a href="#opc" class="heading-links btn w-100 border py-2">One Person Company</a>
            <a href="#ngo" class="heading-links btn w-100 border py-2">NGO Registration</a>
            <a href="#trust" class="heading-links btn w-100 border py-2">Trust Registration</a>
			<a href="#soceity" class="heading-links btn w-100 border py-2">Society / Association Registration</a>
			<a href="#hospital" class="heading-links btn w-100 border py-2">Hospital License</a>
            <a href="#msme" class="heading-links btn w-100 border py-2">MSME Registration</a>
            <a href="#foreign" class="heading-links btn w-100 border py-2">Foriegn Subsidiary Registration</a>
            <a href="#packages" class="heading-links btn w-100 border py-2">Startup Company Packages</a>
		</div>
        <div class="col-lg-9 content">
            <img src="assets/images/s-2.jpg" alt="" class="w-100">
            
            <div class="p-3" id="proprietorship"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/propratership.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Proprietorship </h4>
					<p class="font-7 ser">
						Shop & Establishment Registration Act 1999, for every shop and commercial establishment is
						required to obtain with the Labour
						Department within 30 days of starting your business. It is mandatory for all types Private
						Limited Registered companies, LLP,
						Trust, NGOs and other entities also. In all the states in India. Proprietorship business is
						owned and controlled by one person
						to form a medium size business. Shop Act License is Compulsory to be displayed at all the Shops
						& Companies.
					</p>
					<p class="font-7 ser text-bold">Here's the following business organizations are mandatory to obtain
						their Shops and Establishment’s licenses are</p>
					<p class="font-7 ser">Shops | Commercial establishments | Restaurants | Caterers | Hotels & Clubs |
						Theaters | Public amusement | Retail trade | Institutions | Labs | Clinics | Hospitals |
						Research Centers </p>
					<p class="font-7 ser">
						<span class="text-custom">Benefits: - </span>
						If you Register under Shop and Establishment Act, on the basis of Shop Act License You can
						easily Open a Current Account |
						Company Legal Identity proof | Loans Availability | Smooth inspections | Government benefits |
						Less Legal Compliance that you need to follow .
					</p>
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

            <div class="p-3" id="Partnership"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/partnership-firm.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Partnership Firm </h4>
					<p class="font-7 text-center ser">
						Partnership Firm Act 1932, is one of the most important to form a business organization, where
						minimum 2 and Maximum 20 persons
						required together to form a business and divide the profit and loss in an agreed ratio. A
						Partnership firm is easy to form, and
						the compliance is minimal as compared to companies. Partnership firm can be joined and started
						together by one or more person.
						Among the partners one or two persons may be termed as managing partner who shall manage the day
						to day affairs of the partnership
						business and others will act as a “silent partners”. The partnership firm should be formed as
						soon as the partners enter into the
						partnership deed shall be called as registered partnership firm, wherein the partnership deed is
						registered with a registrar of firms
						formed under the State government and necessary procedural formalities such as filing of return
						etc. as required under law.
						To know more about partnership firm Registration consult us.
						<p class="font-7 ser">
							<span class="text-custom">Benefits: - </span>
							Separate Legal Entity | Low cost of setup | Limited Liability | Ease of Business
						</p>
						<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

            <div class="p-3" id="llc"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/llc.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Limited Liability Partnership </h4>
					<p class="font-7 text-center ser">
						LLP -Limited Liability Partnership Registration governed by LLP Act 2008 combines the benefits
						of a partnership with
						that of a limited liability company. A limited liability partnership is a liable, legal entity
						for the full extent of
						its assets. The liability of the partners, however, is limited. Hence, LLP is a combination
						between a company and a
						partnership. It can be formed with a minimum of two designated partner and there is no maximum
						limit on the partners.
						LLP is an alternative corporate business form that gives the benefits of limited liability as
						present in a company and
						the flexibility of a partnership business. LLPs having a capital amount less than 25 lakhs and
						turnover below 40 lakhs
						per year do not require any formal audits. It makes registering as LLP beneficial for small
						businesses and startups.
						An LLP can own or acquire property because it is recognized as a juristic person. To know more
						about limited liability
						partnerships consult us.
					</p>
					<p class="font-7 ser">
						<span class="text-custom">Benefits: - </span>
						Separate Legal Entity | Low cost of setup | Limited Liability | Easy Way of Business Taxation
						aspect on LLP
						| No requirements of compulsory audit | Dividend Distribution Tax not applicable
					</p>
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

            <div class="p-3" id="pvt"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/pvt-ltd.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Private Limited Company </h4>
					<p class="font-7 text-center ser">
						Private Limited Companies in India is the governing body for Ministry of Corporate Affairs which
						regulates
						all the Companies Act of India, 2013. Private Limited Company Registration is form with a
						minimum of 2
						members and Upto 200 members. A Private Limited Company offers Limited Liability or Legal
						Protection for its
						Shareholders. It is “Limited by Shares” i.e. there are shareholders associated with the company
						and the
						theoretical value of the shares & any paid in return for the issue of shares by the corporation
						is limited to
						the capital which is initially invested. Private restricted should even have minimum of two
						administrators which
						might extend up to fifteen. A private company, or LTD, could be a sort of command a business
						entity. This type
						of business entity limits owner liability to their shares, limits the quantity of shareholders
						to fifty, and
						restricts shareholders from in public commerce shares.
					</p>
					<p class="font-7 ser">
						<span class="text-custom">Benefits: - </span>
						Brand Value | Limited liability | PAN India Office Setup & International Explosure | Improves
						Business credibility
						| Separate legal entity | Attract funding | Continued Existence | Foreign investment
					</p>
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

            <div class="p-3" id="opc"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/opc.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> OPC - One Person Company </h4>
					<p class="font-7 text-center ser">
						OPC Private Limited: - An OPC One Person Company is the new form of business in India introduced
						for the first
						time by the Companies Act, 2013. Company can be started by only one person. An OPC can have more
						than one director
						at least one of them must be an Indian Resident.
					</p>
					<p class="font-7 ser">
						OPC is similar to private limited company can be runned by one member and later it can convert
						into a private
						limited after 2 years of operations or if its turnover is more than Rs 2 crs in a financial
						year.
					</p>
					<p class="font-7 ser">
						<span class="text-custom">Benefits: - </span>
						Safe guard (1 Director) | PAN India Office Setup & International Explosure | Market Value | Easy
						Credit Facilities
						| Easier Return Filing |Exemptions for Board’s Meetings & Report | lesser penalties for OPC
						under Section 446B of the Companies Act, 2013
					</p>
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

			<div class="p-5" id="ngo"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/ngo.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Section 8 NGO Registration </h4>
					<p class="font-7 text-center ser">
						Section 8 company registrations is to a form a NGO under Section 8 of Indian Companies Act,
						2013, and established
						for encouraging and promoting art, science, commerce, charity, religion or any other beneficial
						object and others.
						There may be 3-4 forms of a Charitable Organization in India and such organization can be formed
						/ registered as trusts,
						societies, or as a non-profit company (NGO Registration) incorporated under Section 8 of the
						Companies Act, 2013.
						A NGO with registered can works and develops in a systematic manner and functions accordingly to
						the registration
						and NGO will also help in tax exemption once they are registered under Income Tax Authorities.
					</p>
					<p class="font-7 ser">
						<span class="text-custom">Benefits:- </span>
						No minimum capital requirement| Separate legal entity| Tax exemption| No stamp duty payable| No
						title required | Better credibility as compared to others| Ease of ownership or title transfer
					</p>
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

            <div class="p-5" id="trust"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/trust-reg.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Trust Registration </h4>
					<p class="font-7 text-center ser">
						Trust Registration: A trust is an agreement between people called trustees to manage property
						over which they have
						control either to benefit other people called beneficiaries or for charitable purposes. Groups
						of trustees may be
						incorporated as a board under the Charitable Trusts Act 1957, if the objects are charitable.
						Trust Registration
						is a possible way in which you can register a Non-Government Organisation is by registering it
						as a trust. This
						kind of method is used by NGOs who work with eradicating poverty, giving education and providing
						medical relief,
						Trusts are irrevocable, means that they cannot change or terminate without the permission of the
						Legal Acts.
					</p>
					<p class="font-7 ser">
						<span class="text-custom">Benefits:- </span>
						Land from the government | Several tax benefits such as Income Tax, etc| 80G certificate benefit
						under the Income Tax Act
						| White capital for Building construction| Benefits in Entertainment Tax and Service Tax
					</p>
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

            <div class="p-5" id="soceity"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/society-reg.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Society Registration </h4>
					<p class="font-7 text-center ser">
						Societies Registration Act, 1860 can be referred to member-based organizations for charitable
						purposes.
						Society is a web of people who come along by mutual agreement to act together for a generic
						purpose.
						A Society is built, by steps of a Memorandum of Collaboration, by any seven or above humans
						correlated due
						to any literary, scientific, charitable or religious project idea etc. They are run by a
						managing committee.
						Unlike trusts, all societies and Association Registration come under the preview of one law
						known as Society Registration.
					</p>
					<p class="font-7 ser">
						<span class="text-custom">Benefits:- </span>
						Separate legal identity | Limited Liability | Exemption from income tax | Legal protection
					</p>
					<br>&nbsp; <br>&nbsp;
					<br>&nbsp;
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
			</div>
			
			<div class="p-5" id="hospital"></div>
			<div class="container">
				<div class="container py-4 shadow border">
					<h4 class="text-custom">
						Hospital License
					</h4>
					<p class="mt-4">
						We provide License for Health Care Sectors in Statutory &amp; Regulatory Compliances Registration &amp; License we
						will assess with all the Govt.Bodies &amp; norms, we understand the Medical establishments &amp; Govt.Dept,
						necessitates required Certification &amp; License. Our consultants will assess the License for Hospital, Lab &amp;
						Diagnostic Centres, Clinics, Dental clinics, Ayurveda Centres,. All the certificates &amp; license are mandatory
						to start the medical services. These License is Compulsory to be displayed at all the Medical Service
						providers.
					</p>
					<h4 class="mt-4">
						Our Core Registration Services
					</h4>
					<ul class="mt-4">
						<li>
							Shop &amp; Establishment, Act 1961 License
						</li>
						<li>
							KPME Registration : Karnataka Private Medical Establishment Act 1976
						</li>
						<li>
							Trade License : Karnataka Municipal Corporation Act, 1976
						</li>
						<li>
							AERB Registration : Atomic Energy Regulatory Board: Atomic Energy (Radiation Protection X-Ray &amp; CT Scan)
							Rules 2004.
						</li>
						<li>
							Pollution Control Board : The Karnataka State Pollution Control Board for Prevention and Control Act,
							1981.
						</li>
						<li>
							Bio Medical Waste License : Medical Waste Management (Amendment) Rules, 2018
						</li>
						<li>
							Drugs &amp; Cosmetics Act 1940 License
						</li>
					</ul>
					<h4 class="mt-4">
						Drugs &amp; Cosmetic Licenses Issued By Drug Control Department are
					</h4>
					<ul class="mt-4">
						<li>
							Retail Drug License
						</li>
						<li>
							Wholesale Drug License
						</li>
						<li>
							License to sell Drugs Under schedule X
						</li>
						<li>
							Restricted Licenses
						</li>
						<li>
							License to sell Drugs from the Motor Vehical
						</li>
					</ul>
					<h4 class="mt-4 text-center">
						Click an Appointment Button for your Health Care License
					</h4>
				</div>
			</div>

            <div class="p-5" id="association"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/Association-reg.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Association Registration </h4>
					<p class="font-7 text-center ser">
						Association Registration is an organized group of people with the sole objectives of rendering
						non-profitable
						services for the welfare and development of society. Non-profit organization” shall mean from
						the associations
						registered under this Act 1860, every association that has been formed for charitable cause.
						Registration of
						such societies will have legal identification and are eligible to avail the benefit of
						tax-exemption and also,
						registered associations have to necessarily maintain proceeding reports. This helps maintain
						transparency which
						is required for smooth functioning of the society and also registered societies help you to
						speed up bank related
						procedures for Maintenance work and general welfare are taken care of by the association.
					</p>
					<p class="ser font-7">
						<span class="text-custom">Benifits:- </span>
						Separate legal identity | Exemption from tax | Legal protection
					</p>
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

            <div class="p-5" id="msme"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/msme.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> MSME Registration </h4>
					<p class="font-7 text-center ser">
						MSME - Micro, Small and Medium Enterprises. MSME Registration is mandatory for each small or
						Medium
						Enterprise should enrol with Udyog Aadhar, To avail the benefits under the MSME Act from Central
						or
						State Government and the Banking Sector, MSME Registration is required. This entrance is valid
						for a
						lifetime period and may be obtained by those units or enterprises that have already started
						operations
						and not for the enterprise that is planned to be started. MSME Act of India facilitates
						promotion and
						development of enterprises through many incentives, schemes and subsidies. To obtain the
						benefits under
						MSME Act, MSME Registration is a must for proprietorship firms, partnership firms, LLP, Private
						Limited
						Companies and Public Limited Companies can register.
					</p>
					<p class="font-7 ser">
						<span class="text-custom">Benefits: - </span>
						MSME Loans from Banks | Tax Benefits & Exemptions |Govt Subsidies | Late Payment Recovery
					</p>
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

            <div class="p-5" id="foreign"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/Foreign-Subsidiary.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Foreign Subsidiary Registration </h4>
					<p class="font-7 text-center ser">
						Foreign Subsidy Company is setting up a Business in India by a foreign company it has been
						ranked among
						the top 10 attractive destinations for inbound investments. Reduce corporate tax rate, India
						will
						continue to attract more foreign investors to set up their business in India. Doing business in
						India is easy there are different types of entities a foreign company can register in India.
						Registering a private Limited company is the most common way to start a business in India with
						its
						ease of operations and lower compliance requirement. Foreign subsidiary are required to maintain
						compliance under Income Tax, regulations like TDS regulations, GST regulations, Companies Act
						and various
						other regulations. Foreign subsidiary company would include filing of income tax return with the
						Income
						Tax Department, annual return with the MCA & ROC. The compliance requirement for a foreign
						subsidiary company
						would vary based on the industry, state of incorporation, number of employees. Private limited
						company is a
						wholly owned subsidiary where major shareholding is held by a foreign holding company. Foreign
						investment
						is allowed in many sectors under the automatic route, which makes it easy and fast to set up.
					</p>
					<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
				</div>
            </div>

            <div class="p-5" id="packages"></div>
            <div class="container">
                <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-2 col-5  m-auto">
						<img src="assets/images/propratership.png" class="w-100">
					</div>
					<h4 class="text-center text-custom font-7 mt-3 ser-head"> Startup Registration Packages </h4>
					<p class="font-7 text-center ser">
						We are the "One-Stop-Solution" for Startup Business, having in-house team of professionals in
						all domains and our main motive is to rework your learning at the side of the creation of your
						business Process, so you yourself will head of your business while not looking on totally
						different
						professionals. Mention below packages are solely indicative, these will be customized in keeping
						with
						individual needs. Throughout the assignment, your presence is obligatorily needed at our
						workplace
						and you've got to produce required inputs as per given format by the new source. As we explain
						you
						earlier that this services is categorized under transformative learning to understand more.
						Kindly call us
						to fix an appointment with Experts and discuss in detail on Packages
						<span class="text-bold"> Bronze | Silver | Gold | Customized Package</span>
					</p>
					<p class="font-7 ser">
						<span class="text-custom ">Startup Registration : - </span>
						Proprietorship | Partnership | LLP | OPC PVT LTD | Private limited | Public limited |Section 8
						NGO |
						Trust Registration | Society Registration | MSME Registration | FSSAI License | GST Registration
						|
						PF & ESI Registration | DSC Certificate Registration | Trademark Registration | ISO
						Certification
					</p>
					<a class="btn btn-small  bg-custom border-none text-white shadow" data-toggle="collapse"
						href="#collapse-3" role="button" aria-expanded="false" aria-controls="collapse-3">
						Know More
					</a>
					<div class="collapse p-3" id="collapse-3">
						<p class="font-7 ser">
							<span class="text-custom">Startup Registration: - </span>
							Proprietorship | Partnership | LLP | OPC PVT LTD | Private limited | Public limited |Section
							8 NGO |
							Trust Registration | Society Registration | MSME Registration | FSSAI License | GST
							Registration |
							PF & ESI Registration | DSC Certificate Registration
						</p>
						<p class="font-7 ser">
							<span class="text-custom">Digital Marketing: - </span>
							Web Designing with Content | Logo Design | Visiting Card | Brochure | Letter Head | ID card
						</p>
						<p class="font-7 ser">
							<span class="text-custom">Brand Protection & Brand Image: - </span>
							Trademark Registration | ISO Certification
						</p>
						<p class="font-7 ser">
							<span class="text-custom">Other Services: - </span>
							Import Export Code Registration | Barcode Registration | Accounting Software | Banking
							Support on Current Account | Virtual Office Space
						</p>
						<a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
					</div>
				</div>
            </div>

        </div>
    </div>
</div>

<app-footer></app-footer>