
<div class="container mt-4 shadow py-3">
    <h5 class="text-bold text-center">
        Disclaimer : The information in this Website is for the purpose of knowledge to improve your business and
        keep you updated on Company Acts,
        Legal Documentation & Brand Protection to run a Successful Business. We reserve the rights to modify or
        amend the Content and images time to time and
        it will become effective after 24 hours of posting on new updates.
    </h5>
</div>

<div class="container">
    <h2 class="mt-4 blink-2 text-center">Cancellation and Refund Policy</h2>
    <h4 class="text-custom mt-3 text-center"> Thank you for choosing us !!!! Your satisfaction is very important to
        us. </h4>
    <p class="mt-4">
        Stars Groups want to make sure that our clients have a beneficial experience while they are obtaining
        information, assessing, and registering . We take self trust in the services delivered by us and guarantee
        your satisfaction with our services and end-to-end support. We always improve and try to deliver the best in
        New Company Registration, IPR Services, ISO Certification, Digital Marketing services. In case you are not
        satisfied with our services, please contact us immediately and we will correct the present situation, and
        provide a refund.
    </p>
    <p>
        The following terms and conditions that govern the Refund PolicyStars Groups, reserves the right to
        postpone/cancel a services, or change the location of a services because of insufficient enrolments,
        instructor illness or force-major events (like floods, earthquakes, political instability, etc.)
    </p>
    <h4 class="mt-5 text-center">
        Please view below mentioned terms & Conditions, If you have any comments queries please mail us at :
        <a href="mailto:refund@starsconsultant.com"
            class="btn bg-custom shadow text-white">refund@starsconsultant.com</a>
    </h4>
    <h3 class="text-custom mt-5 text-center"> Terms & Conditions </h3>
    <p class="mt-4">
        The time dedicated for incurring costs and manpower , technology, resources and effort to your service or
        document preparation, our guarantee only covers fulfilment issues caused by Stars groups - does not change
        to your situation or state of mind. In case if you require us to hold processing a service, we will hold the
        fee paid on your account until you are ready to commence the service.
    </p>
    <h3 class="text-custom mt-5 text-center"> Refund Policy </h3>
    <p class="mt-4">
        The Registration fees paid to Stars groups, the fee paid in advance is retained to client account only.
        Refund cannot be provided because of consultation ,man power and hours spent on delivering the service are
        not refundable in natural law. Further, we can't refund or credit any money paid to government entities, or
        to other third parties with a role in processing your registration services.
    </p>
    <h3 class="text-custom mt-5 text-center"> Cancellation Policy </h3>
    <p class="mt-4">
        We ensure to make best effort to complete the service before processing any refund. In case, if you are not
        satisfied with our service, In case of cancelling , the services refund will be paid to the clients. If a
        cancellation is done by a client in 7 days or more, prior to the registration, 30% of Professional fees &
        Paid Government fees will be deducted from the total fees paid and the remaining amount will be refunded
        back to the client . In case of change of service, the cancellation fee would not be applicable on the
        professional fees, Government fees Paid ,full payment will be deducted.if any clarification on the given
        info, kindly mail us
    </p>
    <a href="mailto:refund@starsconsultant.com"
        class="btn bg-custom shadow text-white">refund@starsconsultant.com</a>
</div>

<app-footer></app-footer>
