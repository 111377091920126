<div class="container mt-5">
    <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
        <div class="col-lg-2 col-5  m-auto">
            <img src="assets/images/llc-2.png" class="w-100">
        </div>
        <h4 class="text-center text-custom font-7 mt-3 ser-head"> LLC-Limited Liability Company </h4>
        <p class="font-7 text-center ser">
            LLC is the abbreviation for Limited Liability Company. LLCs are relatively new business
            structures that combine the features of a corporation and a partnership, specifically the
            tax benefits of partnerships and the liability protections of a corporation. You can now
            start up in different countries on your terms, without moving your operations from India.
            We’ll analyse your requirements and help you for registering a company in a particular
            country.
        </p>
        <p class="font-7 ser">
            <span class="text-custom">Benefits: - </span>
            International Office setup | Global Recognition | Less risk | Flexible to run Business |
            Pass through Taxation.
        </p>
        <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
    </div>
</div>

<div class="container mt-5">
    <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
        <div class="col-lg-2 col-5  m-auto">
            <img src="assets/images/inc.png" class="w-100">
        </div>
        <h4 class="text-center text-custom font-7 mt-3 ser-head"> INC-In-Corporation </h4>
        <p class="font-7 text-center ser">
            INC-In-Corporation:-Inc. Is the abbreviation for incorporated. Associate incorporated
            company, or corporation, is a separate legal entity from the person or people forming it.
            Rectors and officers purchase shares in the business and have responsibility for its
            operation. Incorporation limits an individual's liability in case of a lawsuit.
        </p>
        <p class="ser font-7">
            <span class="text-custom">Benefits: - </span>
            Business-friendly laws and regulations | Personal assets | savings are protected from risks
            | Low Corporate income tax rates
        </p>
        <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
    </div>
</div>

<div class="container mt-5">
    <div class="text-center bg-white p-2 mt-5 border shadow" data-aos="fade-up" data-aos-delay="200">
        <div class="col-lg-2 col-5  m-auto">
            <img src="assets/images/tm-reg.png" class="w-100">
        </div>
        <h4 class="text-center text-custom font-7 mt-3 ser-head"> Trademarks Registration </h4>
        <p class="font-7 text-center ser">
            Trademark Registration in globe is the best way to protect your brand by the way of
            "Trademark", which is a legal right. Your brand is your most important asset and that’s how
            your customers identify you. As your business grows, your brand name, logo or even tag-line
            will stand out for credibility, reputation and quality of your business. It is
            cost-effective and has several advantages.
        </p>
        <p class="ser font-7">
            <span class="text-custom">Benefits: - </span>
            Your Exclusive right to Protect the Brand name Globally | It ‘s your IPR Asset | Increases
            your business and valuation | Legal Protection | Monitor your brand in globe | Expand easily
            | Carbon copycats
        </p>
        <a routerLink="/contact" class="bg-custom btn btn-md m-auto text-white shadow"> Know More </a>
    </div>
</div>

<app-footer></app-footer>