
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalComponent } from './digital/digital.component';
import { ContactComponent } from './contact/contact.component';
import { OverseasComponent } from './overseas/overseas.component';
import { BusinessComponent } from './business/business.component';
import { StartupComponent } from './startup/startup.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { RefundComponent } from './refund/refund.component';




const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'startup',
    component: StartupComponent
  },
  {
    path: 'business',
    component: BusinessComponent
  },
  {
    path: 'digital',
    component: DigitalComponent
  },
  {
    path: 'overseas',
    component: OverseasComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'refund',
    component: RefundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
