<img src="assets/images/contact.jpg" class="w-100">
		
		<div class="container mt-5 shadow p-4 border">
			<h3 class="text-center font-7"> <span class="text-custom"> Thank You </span> For Visiting Us </h3>
		</div>
		
		<!-- contact -->

		<div class="container mt-5 p-3">
			<div class="row">
				<div class="col-lg-6">
					<div class="col-lg-12 text-center">
						<h3 class="text-custom"> Enquiry </h3>
					</div>
					<div class="col-lg-12">
						<form class="p-3" action="contactform.php" method="post">
							<input type="text" placeholder="Enter Name" class="form-control shadow" name="name" required>
							<input type="text" placeholder="Enter Phone" class="form-control mt-3 shadow" name="phone"required>
							<input type="text" placeholder="Enter Email ID" class="form-control mt-3 shadow"name="mail" required>
							<textarea class="form-control mt-3 shadow" rows="3" name="comments" required>

							</textarea>
							<input type="submit" name="submit" class="btn btn-lg bg-custom mt-3 shadow text-white m-auto shake-little" value="Submit">
						</form>
					</div>
				</div>
				<div class="col-lg-6">
					<iframe class="shadow" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d124438.44458223738!2d77.51783887840493!3d12.926903719916856!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16c2be83d515%3A0xeacee4a332eb2596!2sStars+Groups!5e0!3m2!1sen!2sin!4v1548052509147" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>
				</div>
			</div>
		</div>

		<section class="jumbotron mt-4 media-gutter">
			<div class="container media-gutter">
				<div class="row">
					<div class="col-lg-6 media-gutter mt-5">
						<h2 class="font-7 border-bottom"> <span class="fa fa-phone text-custom"></span> &nbsp;&nbsp;&nbsp; Phone No </h2>
						
						<div class="col-lg-12 col-12 mt-3 media-gutter">
							<div class="row media-gutter">
								<div class="col-lg-7 media-gutter">
									<h5 class="font-7 text-bold"> Company Registration </h5>
								</div>
								<div class="col-lg-5 media-gutter">
									<a href="tel:9620049886" class="text-bold">9620049886</a>
								</div>
							</div>  
						</div>
						
						<div class="col-lg-12 col-12 mt-3 media-gutter">
							<div class="row media-gutter">
								<div class="col-lg-7 media-gutter">
									<h5 class="font-7 text-bold"> Trademark Registration </h5>
								</div>
								<div class="col-lg-5 media-gutter">
									<a href="tel:7829601111" class="text-bold">7829601111</a>
								</div>
							</div>
						</div>
						
						<div class="col-lg-12 col-12 mt-3 media-gutter">
							<div class="row media-gutter">
								<div class="col-lg-7 media-gutter">
									<h5 class="font-7 text-bold"> FSSAI Registration </h5>
								</div>
								<div class="col-lg-5 media-gutter">
									<a href="tel:9620806777" class="text-bold">9620806777</a>
								</div>
							</div>
						</div>
						
						<div class="col-lg-12 col-12 mt-3 media-gutter">
							<div class="row media-gutter">
								<div class="col-lg-7 media-gutter">
									<h5 class="font-7 text-bold"> ISO Certification </h5>
								</div>
								<div class="col-lg-5 media-gutter">
									<a href="tel:9620740555" class="text-bold">9620740555</a>
								</div>
							</div>
						</div>
						
					</div>
					<div class="col-lg-6 mt-5">
						<h2 class="font-7 border-bottom"> <span class="fa fa-envelope text-custom"></span> &nbsp;&nbsp;&nbsp; Email </h2>
						
						<div class="col-lg-12 col-12 mt-3 media-gutter">
							<div class="row media-gutter">
								<div class="col-lg-5 media-gutter">
									<h5 class="font-7"> <span class="fa fa-user"></span> &nbsp;&nbsp;&nbsp; Enquiry </h5>
								</div>
								<div class="col-lg-7 media-gutter">
									<a href="mailto:info@startupcompanyregistration.com" class="text-black">	info@startupcompanyregistration.com  </a>
								</div>
							</div>
						</div>

						<div class="col-lg-12 col-12 mt-3 media-gutter">
							<div class="row media-gutter">
								<div class="col-lg-5 media-gutter">
									<h5 class="font-7"> <span class="fa fa-handshake-o"></span> &nbsp;&nbsp;&nbsp; Consultant </h5>
								</div>
								<div class="col-lg-7 media-gutter">
									<a href="mailto:consultant@startupcompanyregistration.com" class="text-black"> consultant@startupcompanyregistration.com </a>
								</div>
							</div>
						</div>

						<div class="col-lg-12 col-12 mt-3 media-gutter">
							<div class="row media-gutter">
								<div class="col-lg-5 media-gutter">
									<h5 class="font-7"> <span class="fa fa-gears"></span> &nbsp;&nbsp;&nbsp; Support </h5>
								</div>
								<div class="col-lg-7 media-gutter">
									<a href="mailto:support@startupcompanyregistration.com" class="text-black"> support@startupcompanyregistration.com </a>
								</div>
							</div>
						</div>
						
						<div class="col-lg-12 col-12 mt-3 media-gutter">
							<div class="row media-gutter">
								<div class="col-lg-5 media-gutter">
									<h5 class="font-7"> <span class="fa fa-smile-o"></span> &nbsp;&nbsp;&nbsp; Feedback </h5>
								</div>
								<div class="col-lg-7 media-gutter">
									<a href="mailto:feedback@startupcompanyregistration.com" class="text-black"> feedback@startupcompanyregistration.com  </a>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</section>
		
		<section class="mt-5 container text-center">
			<h2 class="text-center text-custom font-7"> Careers </h2>
			<p class="text-bold text-center mt-3">
				Stars Groups Welcomes young and dynamic candidates who have passion for both personal
				 and Companies growth with innovative and motivational. Exciting challenges, working 
				 with various teams all these opportunities will give positive career for all individuals, 
				 Consider to submit your CV for below Position.
			</p>
			<a href="mailto:careers@startupcompanyregistration.com" class="text-center text-bold mt-3 text-black btn bg-custom shadow text-white">careers@startupcompanyregistration.com</a>
			<div class="row">
				<div class="col-lg-4">
					<div class="card mt-4 p-1 shadow">
						<h3 class="text-custom text-center font-7"> Work </h3>
						<p class="mt-3 text-center font-7">
							we work Smart to gives us a sense of pride, identity and personal 
							achievement for our best legal documentation support to Start-up Companies
						 </p>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="card mt-4 p-1 shadow">
						<h3 class="text-custom text-center font-7"> Learn </h3>
						<p class="mt-3 text-center font-7">
							We encourage our staff in workplace to learn new skills, update the 
							latest process, and develop leadership abilities to get the opportunity to grow
						</p>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="card mt-4 p-1 shadow">
						<h3 class="text-custom text-center font-7"> Earn </h3>
						<p class="mt-3 text-center font-7">
							Earn helps better measure performance and progress. It often produces
							 valuable insight.  The most common earning value approach is Capitalizing Earning. 
							</p>	
					</div>
				</div>
			</div>
		</section>
		
		<section class="mt-5 container">
			<h2 class="text-center text-custom font-7"> Current Opening </h2>
			<div class="row mt-3 no-gutter">
				<div class="col-lg-3 no-gutter mt-2">
					<div class="col-lg-12 bg-custom text-center text-white border-right p-2">
						<h4 class="font-7"> Business Consultant </h4>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Client Handling </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Min. Graduate </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7">  Good Communication </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7">  Fresher to 1 year Experience </p>
					</div>
				</div>
				<div class="col-lg-3 no-gutter mt-2">
					<div class="col-lg-12 bg-custom text-center text-white border-right p-2">
						<h4 class="font-7"> Backend Support </h4>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Good English typing </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Min. Graduate </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Good Communication </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7">  1 year Experience </p>
					</div>
				</div>	
				<div class="col-lg-3 no-gutter mt-2">
					<div class="col-lg-12 bg-custom text-center text-white border-right p-2">
						<h4 class="font-7"> Internship </h4>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Business Development </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Min. Graduate - M.B.A </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Good Communication </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Fresher  </p>
					</div>
				</div>
				<div class="col-lg-3 no-gutter mt-2">
					<div class="col-lg-12 bg-custom text-center text-white border-right p-2">
						<h4 class="font-7"> Accountant </h4>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7"> Accounts Executive </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7">  B.Com, M.Com, M.B.A </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7">  Knowledge in Tally, GST Filling, Payroll </p>
					</div>
					<div class="col-lg-12 text-center border-right border-bottom border-left p-2">
						<p class="font-7">   2 years Experience </p>
					</div>
				</div>
			</div>
			<p class="font-7 mt-4 text-center text-bold"> Send Us Ur CV We Will <span class="text-custom"> Contact </span> You </p>
		</section>

		<app-footer></app-footer>
